var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.isLoading)?_c('div',{staticClass:"flex justify-center align-center mt-10"},[_c('v-progress-circular',{attrs:{"color":(_vm.adkData && _vm.adkData.builderType === 'ideate') || _vm.adkType === 'ideate' ? 'yellow' : 'blue',"indeterminate":"","size":"80"}})],1):_c('div',[_c('div',{staticClass:"pa-6"},[_c('div',{staticClass:"mb-10"},[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold",attrs:{"rounded":"","dark":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-form-select")]),_vm._v(" "+_vm._s(_vm.finalDraftSaved + ' #' + _vm.display)+" ")],1)]}}])},_vm._l((_vm.totalDrafts),function(draft){return _c('v-card',{key:draft,staticClass:"module__menu"},[_c('v-btn',{staticClass:"draft-button mt-2 mb-1",attrs:{"outlined":"","small":"","width":"100%","depressed":"","rounded":""},on:{"click":function($event){return _vm.showDraft(_vm.totalDrafts - draft + 1)}}},[_vm._v(" Draft # "+_vm._s(_vm.totalDrafts - draft + 1)+" ")])],1)}),1)],1),((_vm.adkData && _vm.adkData.builderType === 'ideate') || _vm.adkType === 'ideate')?_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-account-group',
              counter: 280,
              placeholder: 'Identify and describe the user and customer of the solution',
              label: 'User or Customer',
              outlined: true,
              rounded: true,
              autoGrow: true,
              rows: 5
            }},model:{value:(_vm.state.user),callback:function ($$v) {_vm.$set(_vm.state, "user", $$v)},expression:"state.user"}})]}}],null,false,3218684062)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-fire',
              counter: 280,
              placeholder: 'What hair on fire problem or opportunity are you solving for?',
              label: 'Problem or Opportunity',
              outlined: true,
              rounded: true,
              autoGrow: true,
              rows: 5
            }},model:{value:(_vm.state.problem),callback:function ($$v) {_vm.$set(_vm.state, "problem", $$v)},expression:"state.problem"}})]}}],null,false,1152607869)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-lightning-bolt',
              counter: 280,
              placeholder: 'What unique value does your solution deliver?',
              label: 'Innovation or Unique Value Proposition',
              outlined: true,
              rounded: true,
              autoGrow: true,
              rows: 5
            }},model:{value:(_vm.state.innovation),callback:function ($$v) {_vm.$set(_vm.state, "innovation", $$v)},expression:"state.innovation"}})]}}],null,false,265058588)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-head-snowflake',
              counter: 280,
              placeholder: 'What bright idea do you have as a solution?',
              label: 'Solution or Product',
              outlined: true,
              rounded: true,
              autoGrow: true,
              rows: 5
            }},model:{value:(_vm.state.solution),callback:function ($$v) {_vm.$set(_vm.state, "solution", $$v)},expression:"state.solution"}})]}}],null,false,300974349)})],1):_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-text-short',
              counter: 280,
              placeholder: 'Describe your solution in one sentence',
              label: 'One Sentence Pitch',
              outlined: true,
              rounded: true,
              autoGrow: true, // Add this line
              rows: 3 // Add this line to set initial rows
            }},model:{value:(_vm.state.onePitch),callback:function ($$v) {_vm.$set(_vm.state, "onePitch", $$v)},expression:"state.onePitch"}})]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('a-text-area',{attrs:{"error-messages":errors,"disabled":_vm.disabledPastDraft == 1,"styling":{
              class: 'my-5',
              prependInnerIcon: 'mdi-text-subject',
              counter: 500,
              placeholder: 'Write your sixty second elevator pitch',
              label: 'Elevator Pitch',
              outlined: true,
              rounded: true,
              autoGrow: true, // Add this line
              rows: 7 // Add this line to set initial rows
            }},model:{value:(_vm.state.elevatorPitch),callback:function ($$v) {_vm.$set(_vm.state, "elevatorPitch", $$v)},expression:"state.elevatorPitch"}})]}}])})],1),_c('div',{staticClass:"d-flex mb-3",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xs ? 'justify-center m-auto mb-3' : 'mr-auto justify-start'},[(_vm.disabledPastDraft == 0)?_c('v-btn',{staticClass:"perform-adk-ideate-draft",style:({ width: _vm.$vuetify.breakpoint.xs ? '100%' : ' ' }),attrs:{"disabled":_vm.unmakeFD == 1 || invalid || _vm.isMonitor,"rounded":"","loading":_vm.isSavingDraft,"x-large":"","outlined":"","depressed":""},on:{"click":function($event){return _vm.saveDraft(_vm.display)}}},[(_vm.success == true)?_c('v-icon',{attrs:{"value":_vm.success == true,"left":"","color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),_vm._v(" Save Draft ")],1):_vm._e()],1),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xs ? 'justify-center m-auto' : 'ml-auto justify-end'},[(_vm.unmakeFD == 0 && _vm.isValue)?_c('v-btn',{staticClass:"font-weight-bold white--text perform-adk-ideate-draft-final",style:({ width: _vm.$vuetify.breakpoint.xs ? '100%' : ' ' }),attrs:{"disabled":invalid || _vm.isMonitor,"x-large":"","loading":_vm.isSavingFnDraft,"color":"black","rounded":"","depressed":""},on:{"click":function($event){return _vm.finalDraft(_vm.display)}}},[_vm._v(" Make Final Draft ")]):_vm._e(),(_vm.unmakeFD == 1)?_c('v-btn',{staticClass:"font-weight-bold white--text",attrs:{"disabled":invalid || _vm.isMonitor,"loading":_vm.unmaking,"x-large":"","rounded":"","color":"#ea6764","depressed":""},on:{"click":function($event){return _vm.unmakeFinalDraft(_vm.display)}}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-lead-pencil")]),_vm._v(" Continue to Edit ")],1):_vm._e()],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }